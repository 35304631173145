import React, { useState } from 'react';

import { Button, Form, Input, Typography } from 'antd';
import { Link } from 'gatsby';
import LocalizedStrings from 'react-localization';

import { FormPageLayout } from '../components/Layouts';
import { apiRequester, handleError, handleSuccess } from '../utility';

const { Title, Paragraph } = Typography;
const strings = new LocalizedStrings({
    en: {
        successNotif:
            'Please check your inbox for a password reset link. If you did not receive any email, please check your Spam folder.',
        forgotPassword: 'Forgot Virtooally Password',
        enterAccountDetails: 'Enter your account details below.',
        email: 'E-mail',
        enterEmail: 'Please enter your email!',
        sendRecovery: 'Send recovery link',
        backToLogin: 'Back to login',
    },
    de: {
        successNotif:
            'Bitte schauen Sie in Ihrem Postfach nach der E-Mail mit dem Link zum Zurücksetzen des Passwortes. Überprüfen Sie ggf. auch Ihren Spam-Ordner.',
        forgotPassword: 'Virtooally-Passwort vergessen',
        enterAccountDetails: 'Geben Sie nachfolgend Ihre Account-Daten ein',
        email: 'E-Mail-Adresse',
        enterEmail: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
        sendRecovery: 'Wiederherstellungslink senden',
        backToLogin: 'Zurück zum Login',
    },
});

const ForgotPasswordPage = () => {
    const [loading, setLoading] = useState(false);
    const onFinish = async (values: { emailId: string }) => {
        try {
            setLoading(true);
            await apiRequester.forgotPassword(values);
            handleSuccess(strings.successNotif!);
            setLoading(false);
        } catch (err) {
            handleError(err);
            setLoading(false);
        }
    };
    return (
        <FormPageLayout>
            <div style={{ marginBottom: '2rem' }}>
                <Title level={2} style={{ marginBottom: '0' }}>
                    {strings.forgotPassword}
                </Title>
                <Paragraph>{strings.enterAccountDetails}</Paragraph>
            </div>
            <Form onFinish={onFinish}>
                <Form.Item
                    label={strings.email}
                    labelCol={{ span: 24 }}
                    name="emailId"
                    rules={[{ required: true, type: 'email', message: strings.enterEmail }]}
                >
                    <Input style={{ height: '3rem' }} />
                </Form.Item>
                <Form.Item>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Button type="primary" htmlType="submit" loading={loading} style={{ height: '3rem' }}>
                            {strings.sendRecovery}
                        </Button>
                        <Link to="/login">{strings.backToLogin}</Link>
                    </div>
                </Form.Item>
            </Form>
        </FormPageLayout>
    );
};

export default ForgotPasswordPage;
